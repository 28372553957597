import React, { Component } from 'react';
import axios from 'axios';
import ModalViewNFT from '../components/Modal/ModalViewNFT';

import HomeScroller from '../components/Home/HomeScroller';
import Header from '../components/Header/Header';
import HomeFeatured from '../components/Home/HomeFeatured';
import HomeTabs from '../components/Home/HomeTabs';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';

class ThemeOne extends Component {

		/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	state = {
		tab:"assets",
		useModalViewNFTs:true,
		modalVisible:false,
		prevY: 0,
		loading:true,
		loadingNFT:true,
		homePageData:{"featured":[], forSale:[], minted:[], recentSales:[], mintedxrpl:[]},
		nfts:[],
		nft:{
			creator:{"headerImage":"", "id":"", "url":"", "profileImageM": this.props.config.sizes.placeholders.profilePicture.medium, "title":""},
			collection: {url:"", "name":"", title:"", "preview":""},
			auction:{end:0, isAuction:false, bids:[]},
			mintedQty:false,
			mintPending:false,
			mintTransferPending:false,
			flagOwner:false,
			flagEditable:false,
			flagCreator:false,
			flagForSale:false,
			flagMadeAnOffer:false,
			floorPrice:0,
			ceilingPrice:0,
			description:"",
			burnable:false,
			attributes:{},
			owners:[],
			media:[],
			history:[],
			offers:{ buy:[], sell:{}, forSale:false, forSalePrice:0, offersMade:0, buyOfferMade:false },
			mintAddresses:[],
			metaData:{mediaType:"", width:0, height:0},
			preview: this.props.config.sizes.placeholders.square,
			supply:"?",
			size:"?",
			title:"?",
			previewMedia:"",
			previewAudio:""
		},
		collection:this.props.collection,
		dataType:this.props.dataType,
		infiniteScroll:this.props.infiniteScroll,
		bulkCollection:this.props.bulkCollection,
		maxResults:this.props.maxResults,
		offset:0,
		itemsPerPage:20,
		totalFilteredNFTs:-1,
		totalLoaded:0,
		collectionFilters:[],
		sortOrder:"",
		showSearchStuff:false,
		searchCriteria:"",

		filterType:"",
		showFilterHTML:false,
		filterHTML:"",
		loadedInitial:false,
		mediaPlaying:false,
		disableTransfers:false,

		selectedForSale:[],

	};

	myCallbackModal = (visible) => {


		/* This enables grandchild component to change the state of modal tab visibility */
		this.setState({modalVisible:visible})

		/* Scroll to top */
		window.setTimeout( () => {
			//alert(1)
			//document.getElementById("view-nft-modal").scrollTop = document.documentElement.scrollTop = 0;
		}, 500);


	}

	myCallbackPlaybutton = e => {
		/* Enable play of preview video */
		this.setState({mediaPlaying:! this.state.mediaPlaying})
	}

	permaLink = e =>
	{
		const _link = this.props.config.serverURL + e.currentTarget.dataset.link;

		if( typeof(this.props.subpage) === "undefined" )
		{
			const e = document.createElement('input');
			e.id = "clipboard";
			document.body.appendChild(e);
		}

		const cb = document.getElementById("clipboard")
		cb.style = "display:block";

		/* Fill and select the text field */
		cb.value = _link;
		//cb.focus()
		cb.select();
		cb.setSelectionRange(0, 99999); /*For mobile devices*/

		/* Copy the text inside the text field */
		document.execCommand("copy");

		cb.style = "display:none";
		//$('#clipboard').hide();

		/* Alert the copied text */
		window.displayMessage("The permalink has been copied to your clipboard");

	}

	loadNFT = e =>
	{

		const _id = e.currentTarget ? e.currentTarget.dataset.nft : e;

		this.setState({loadingNFT:true});

		/* Change to preloader image */
		window.$('#viewing-nft-image').prop( "src", this.props.config.sizes.placeholders.loader);

		const BASE_URL = this.props.config.apiServer + "/com/external/nfts.cfc?method=getNFT&uuid="+_id;

		axios.get(`${BASE_URL}`, {withCredentials: true})
			.then(res => {

				if( ! res.data.success)
				{
					window.displayErrorMessage("Invalid NFT - Reloading collection", false, false);
				}
				else{
					window.$('#breadcrumb-2 a').prop("href", res.data.nft.collection.url).html(res.data.nft.collection.name)

					/* Ensure images and URL's point to API server */
					var cachebuster = Math.round(new Date().getTime() / 1000);
					//res.data.nft.preview = res.data.nft.preview.length ? this.props.config.apiServer + res.data.nft.preview + "?cb="+cachebuster: "";
					res.data.nft.creator.headerImage = res.data.nft.creator.headerImage.length ? this.props.config.apiServer + res.data.nft.creator.headerImage : "";
					//res.data.nft.media = res.data.nft.media ? this.props.config.apiServer + res.data.nft.media : "";

					/* Copy to window for jQuery sharing */
					window.nftData = res.data.nft;
					var _tab = "assets"
					if( res.data.nft.offers.offersMade && res.data.nft.flagOwner > 0 ) { _tab = "offers" }		/* We have offers and are the owner */
					if( res.data.nft.offers.buyOfferMade ) { _tab = "offers" }									/* Buyer has made an offer */

					this.setState({
						isMine:res.data.nft.flagOwner,
						buyDisabled:res.data.nft.flagOwner ? true : false,
						nft: res.data.nft,
						loadingNFT:true,
						tab:_tab,
						playMedia:false,
						modalVisible:false,
						mediaPlaying:false
					})

					/* Reveal page parts */
					this.setState({loadingNFT:false});

				}
			})
		.catch(err => window.logger(err))

	}

	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */


	componentDidMount(){


        this.setState({
            config: this.props.config,
			loading:true,
			homePageData:{"featured":[], forSale:[], minted:[], recentSales:[], mintedxrpl:[]},
			isFeaturedCollections:true,
			isMyCollection:false,
			showHeading:true,
			cardClass:"col-sm-1 col-xs-1 col-md-4 col-lg-3",
			maxResults: typeof(this.props.maxResults) != "undefined" ? this.props.maxResults : 12
        })

		this.loadData()

    }


	loadData = e =>
	{

		this.setState({
			loading:true
		})

		const formData = new FormData( );
		formData.append("method", "getItems")
		formData.append("results", 13)

		const BASE_URL = this.props.config.apiServer + "/com/external/homePageData.cfc";

		axios.post(`${BASE_URL}`, formData, {withCredentials: true})
			.then(res => {

				if(res.data.success)
				{
					this.setState({
						homePageData: res.data.items,
						loading:false
					})

					/* https://github.com/xmart-git/jquery-flipster */
					window.setTimeout( () => {
								window.$('.my-flipster').flipster( {
									style:"coverflow",
									start:6,
									autoplay:2500,
									nav:false,
									buttons:this.props.config.isMobile ? false : true,
									loop:true

								} ).removeClass("notVisible");
					}, 300)

				}
				else
				{
					throw new Error(res.data.errorMessage)
				}




			})
		.catch(err =>{ window.displayErrorMessage(err.message); window.logger(err.message) })


		return
	}


	render() {

		document.title = "Ooof";

        return (
            <div className="main">

				<Header config={this.props.config} />

				<section className="popular-collections-area home-featured">

					<div className="container p-2 pt-5">
					<h1 className="text-center">{this.props.config.space.name}</h1>
					</div>
				</section>


				{/* NOTE... NOTE... NOTE... NOTE... NOTE... NOTE... The modal wrap is HERE and not in the component as it's used in view.js and would make the page invisible */}
				{/* NOTE... NOTE... NOTE... NOTE... NOTE... NOTE... The modal wrap is HERE and not in the component as it's used in view.js and would make the page invisible */}
				{/* NOTE... NOTE... NOTE... NOTE... NOTE... NOTE... The modal wrap is HERE and not in the component as it's used in view.js and would make the page invisible */}
				<div id="view-nft-modal" className="modal fade p-0">
					<div className="modal-dialog modal-xl dialog-animatedx modal-fullheight modal-fullscreen-lg-down">
						<div className="modal-content h-100">
							{/* Button to launch NFT viewer */}
							<button type="button" className="hid" id="nft-popper-button" data-bs-toggle="modal" data-nft="" data-bs-target="#view-nft-modal" onClick={this.loadNFT}>Show modal</button>
							{/* The actual viewing section */}
							<ModalViewNFT config={this.props.config} state={this.state} modalCallBack={this.myCallbackModal} playButtonCallBack={this.myCallbackPlayButton} loadNFT={this.loadNFT} componentCaleldFrom="main.js" fullPage={false} />
						</div>
					</div>
				</div>

				{/* <Work /> */ }
				<Footer config={this.props.config} />
				<ModalSearch config={this.props.config} />
				<ModalMenu config={this.props.config} />
				<Scrollup config={this.props.config} />



            </div>
        );
    }
}

export default ThemeOne;